
//公共库
import { Component, Vue } from "vue-property-decorator";

//组件
@Component({
  name: "AuthRedirect",
})

//组件函数
export default class extends Vue {
  //创建时调用
  created() {
    //数据赋值
    const hash = window.location.search.slice(1);

    //本地存储
    if (window.localStorage) {
      //保存
      window.localStorage.setItem("x-admin-oauth-code", hash);

      //关闭
      window.close();
    }
  }

  render() {}
}
